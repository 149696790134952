export default {
	common: {
		rechargePic: "Recharge voucher",
		levelEight: "Bank card channel freeze",
		realYes: "Aadhaar, Driving License",
		realNo: "Aadhaar, Driving License",
		realTitle: "Personal ID",
		realname: "Real name (Same name as ID)",
		cardNumber: "ID Number",
		cardMain: "ID card front",
		cardBack: "ID card Back",
		cardPerson: "Selfie ID",
		frozen_time: "Order freeze countdown",
		payment_time: "Payment Time",
		get_time: "Get Time",
		unfreeze: "Unfreeze",
		please_captcha: "Verification Code",
		top: "Get Code",
		app_name: "E-Service",
		currency_unit: "$",
		confirm: "Confirm",
		cancel: "Cancel",
		all: "All",
		save: "Deposit",
		no_data: "No Data",
        copy_success: "Successfully copied!",
		copy_error: "Copy Failed",
		tip: "Tips",
		to: "To",
		search: "Search",
		freezeMessage: "Please contact your supervisor",
        completeOrder: "Are you sure you want to complete this order?",
        logout: "Are you sure you want to log out?",
        currency: "$",
        deposit: "Deposit",
        withdraw: "Withdraw",
        invite: "Invite",
        balance: "Balance",
        deposit_successful: "Deposit Successful",
        withdraw_successful: "Withdraw Successful",
        deposit_fail: "Deposit Fail",
        details: "Details",
        add: "Add",
        remind: "Remind",
        success: "Success",
        description: "Description",
        submit: "Submit",
        no_record: "No Record",
		countryCode: "Country code",
        withdraw0: "APPLY",
        withdraw1: "PENDING",
        withdraw2: "SUCCESS",
        withdraw3: "FAILED",
        orderNow: "Order Now",
        noBalance: "The available balance of the account is insufficient !",
        needTo: "The available balance of the account is insufficient ! You need to recharge ",
	},
	form: {
		label_username: "Username",
		place_username: "Please Enter Your username",
		place_select: "Place Select",
		label_phone: "Mobile Number",
		place_phone: "Enter your Mobile Number",
		label_password: "Password",
		place_password: "Please Enter Password",
		label_create_pwd: "Create Password",
		place_create_pwd: "Please Enter Create Password",
		label_confirm_pwd: "Confirm Password",
		place_confirm_pwd: "Confirm Password",
		label_employee_code: "Invitation Code",
		place_employee_code: "Invitation Code",
		place_amount: "Place enter Amount",
		label_account_name: "Account Name",
		place_account_name: "Place Enter Your Account Name",
		label_bank_name: "Wallet Type",
		place_bank_name: "Place Enter Your Wallet Type",
		label_account_number: "Wallet Address",
		place_account_number: "Place Enter Your Bank Wallet Address",
		label_ifsc: "IFSC",
		place_ifsc: "Place Enter Your IFSC",
		label_email: "Email",
		place_email: "Place Enter Your Email",
		label_old_pwd: "Old Password",
		place_old_pwd: "Place Enter Old Password",
		label_new_pwd: "New Password",
		place_new_pwd: "Place Enter New Password",
		label_crm_pwd: "Confirm Password",
		place_crm_pwd: "Place Enter New Password",
		label_name: "Name",
		place_name: "Please Enter Your Name",
	},
	account: {
		login: "Login",
		remember: "Remember My Password",
		apply_now: "Register",
		service: "Customer Service",
		apply_completed: "Completed",
	},
	mission: {
		title: "Target Goals",
		cashback: "Cashback",
		todays_profit: "Today's Profit",
		total_profit: "Total Profit",
		project_completed: "Order completed",
		pause_ads: "Freeze order",
		process_ads: "Order in progress",
		unsettled_project_cost: "Frozen amount",
		my_group: "My Group",
		project: "Remaining orders",
		get_project: "Get A Project",
		mission_tip1: "Information",
		mission_tip2: "Once you've created the ads, you'll have a random campaign to generate click-through income.",
		mission_tip3: "If you leave your post ads unattended within the specified time, the system will automatically pause them; kindly contact your agent as soon as you can for assistance.",
		count_down: "CountDown",
	},
	order: {
		project_list: "Project List",
		work_load: "In progress",
		pause: "Freeze",
		succeeded: "Succeeded",
		collection_time: "Collection time",
		order_number: "Order number",
		total_order_amount: "Total order amount",
		commission: "commission",
		refund_commission: "Refund of commission",
	},
	my: {
		copy_link: "Copy",
		add_bank: "Add Wallet Address",
		message: "Message",
		bank_account: "Wallet Address",
		save_record: "Saving Record",
		withdraw_record: "Withdrawal Record",
		progressing: "Progressing",
		invite: "Invite",
		employee_code: "Employee Code",
		naziy: "naziy",
		my_profile: "My Profile",
		work_loads: "Work Loads",
		group_report: "Group Report",
		logout_tit: "This operation will exit the login. Do you want to continue?",
		logout_success: "Exit successful!",
		logout_filed: "Canceled exit!",
		setting: "Settings",
		change_pwd: "Change Password",
		user_id: "User ID",
		given_name: "User Name",
		surname: "Surname",
		email: "E-mail",
		bank_account_number: "My Wallet Address",
		modify_pay_pwd: "Modify Payment Password",
		group_balance: "Group Balance",
		total_group_savings: "Total Group Savings",
		group_profit: "Group Profit",
		group_progress: "Group Progress",
		total_group_withdrawal: "Total Group Withdrawal",
		groups_members: "Number of Groups members",
		trainee: "Trainee",
		entry_level: "Entry Level",
		junior: "Junior",
		commission: "commission",
	},
	home: {
		notification: "Notification",
		notification_p1: "BIGcommerce marketing that turns visitors into loyal customers",
		notification_p2: "Place store and customer data at the forefront of your e-commerce marketing strategy with BRANDEMIC. Fuse cross-channel marketing with 1:1 personalization to drive ROI and customer loyalty.The goal of BRANDEMIC is to create a fruitful advertising campaign for e-commerce.",
		welcome_p1: "BRANDEMIC",
		welcome_p2: "Welcome Back",
		my_credit: "My Credit",
		save: "Deposit",
		withdraw: "Withdraw",
		fecsfs_tab1: "Current Paycheck",
		fecsfs_tab2: "Previous's Paycheck",
		fecsfs_tab3: "Summative Paycheck",
		successful_withdrawals: "Successful Withdrawals",
		withdrawal_amount: "Withdrawal Amount",
		about_us: "About Us",
		privacy_policy: "Privacy Policy",
		faq: "FAQ",
		our_clients: "Our Clients",
	},
	asset: {
		savings: "SAVINGS",
		credit_balance: "Credit Balance",
		savings_amount: "Enter amount",
		mode_payment: "Mode of Payment",
		reminder: "Reminder",
		reminder_p1: "The UPI platform may alter at any time. Please refresh to see the most recent account for transactions.",
		reminder_p2: "If you experience any issues, kindly contact your Company Representative",
		withdrawal_bank_card: "Withdrawal Address",
		balance: "Balance",
		reminder_p3: "①The minimum withdrawal amount is 220 or more before the withdrawal can be successful.",
		reminder_p4: "②5% fee deducted from each withdrawal.",
		reminder_p5: "③After completing the withdrawal application, the platform will proceed in order, please wait patiently.",
	},
	city: {
		albania: "Albania",
		algeria: "Algeria",
		argentina: "Argentina",
		armenia: "Armenia",
		australia: "Australia",
		pakistan: "Pakistan",
		austria: "Austria",
		bahrain: "Bahrain",
		belgium: "Belgium",
		bosnia_and_Herzegovina: "Bosnia and Herzegovina",
		brazil: "Brazil",
		brunei: "Brunei",
		bulgaria: "Bulgaria",
		cambodia: "Cambodia",
		canada: "Canada",
		cameroon: "Cameroon",
		chile: "Chile",
		colombia: "Colombia",
		costa_Rica: "Costa Rica",
		croatia: "Croatia",
		cyprus: "Cyprus",
		czech_Republic: "Czech Republic",
		denmark: "Denmark",
		dominican_Republic: "Dominican Republic",
		egypt: "Egypt",
		estonia: "Estonia",
		ethiopia: "Ethiopia",
		finland: "Finland",
		france: "France",
		georgia: "Georgia",
		germany: "Germany",
		ghana: "Ghana",
		greece: "Greece",
		guyana: "Guyana",
		honduras: "Honduras",
		hong_Kong_China: "Hong Kong, China",
		hungary: "Hungary",
		iceland: "Iceland",
		ireland: "Ireland",
		italy: "Italy",
		india: "India",
		indonesia: "Indonesia",
		israel: "Israel",
		iran: "Iran",
		iraq: "Iraq",
		japan: "Japan",
		kazakstan: "Kazakhstan",
		kenya: "Kenya",
		korea: "Korea",
		kuwait: "Kuwait",
		kyrgyzstan: "Kyrgyzstan",
		laos: "Laos",
		latvia: "Latvia",
		lithuania: "Lithuania",
		luxembourg: "Luxembourg",
		macao_China: "Macao_China",
		macedonia: "Macedonia",
		malaysia: "Malaysia",
		malta: "Malta",
		mexico: "Mexico",
		moldova: "Moldova",
		monaco: "Monaco",
		mongolia: "Mongolia",
		montenegro: "Montenegro",
		morocco: "Morocco",
		myanmar: "Myanmar",
		netherlands: "Netherlands",
		new_Zealand: " New_Zealand",
		nepal: "Nepal",
		nigeria: "Nigeria",
		norway: "Norse",
		oman: "Oman",
		palestine: "Palestine",
		panama: "Panama",
		paraguay: "Paraguay",
		peru: "Peru",
		philippines: "Philippines",
		poland: "Poland",
		portugal: "Portugal",
		puerto_Rico: "Puerto Rico",
		qatar: "Qatar",
		romania: "Romania",
		russia: "Russia",
		republic_of_Trinidad_and_Tobago: "Republic of Trinidad and Tobago",
		rwanda: "Rwanda",
		saudi_Arabia: "Saudi Arabia",
		serbia: "Serbia",
		singapore: "Singapore",
		slovakia: "Slovakia",
		slovenia: "Slovenia",
		south_Africa: "South Africa",
		spain: "Spain",
		sri_Lanka: "Sri Lanka",
		sweden: "Sweden",
		switzerland: "Switzerland",
		taiwan_China: "Taiwan_China",
		tajikistan: "Tajikistan",
		tanzania: "Tanzania",
		thailand: "Thailand",
		turkey: "Turkey",
		turkmenistan: "Turkmenistan",
		ukraine: "Ukraine",
		united_Arab_Emirates: "United Arab Emirates",
		united_Kingdom: "United Kingdom",
		united_States: " United_States",
		uzbekistan: "Uzbekistan",
		venezuela: "Venezuela",
		vietnam: "Vietnam",
		afghanistan: "Afghanistan",
		angola: "Angola",
		azerbaijan: "Azerbaijan",
		bangladesh: "Bangladesh",
		belarus: "Belarus",
		belize: "Belize",
		benin: "Benin",
		bhutan: "Bhutan",
		bolivia: "Bolivia",
		botswana: "Botswana",
		british_Virgin_Islands: "British Virgin Islands",
		burkina_Faso: "Burkina Faso",
		burundi: "Burundi",
		cape_Verde: "Cape Verde",
		cayman_Islands: "Cayman Islands",
		central_African_Republic: "Central African Republic",
		chad: "Chad",
		comoros: "Comoros",
		the_Republic_of_the_Congo: " The_Republic_of_the_Congo",
		democratic_Republic_of_the_Congo: "Democratic Republic of the Congo",
		djibouti: "Djibouti",
		ecuador: "Ecuador",
		el_Salvador: "El Salvador",
		equatorial_Guinea: "Equatorial Guinea",
		eritrea: "Eritrea",
		fiji: "Fiji",
		gabon: "Gabon",
		gambia: "Gambia",
		greenland: "Greenland",
		guatemala: "Guatemala",
		guinea: "Guinea",
		haiti: "Haiti",
		isle_of_Man: " Isle_of_Man",
		cote_d_Ivoire: "Cote_d_Ivoir",
		jamaica: "Jamaica",
		jordan: "Jordan",
		lebanon: "Lebanon",
		lesotho: "Lesotho",
		liberia: "Liberia",
		libya: "Libya",
		madagascar: "Madagascar",
		malawi: "Malawi",
		maldives: "Maldives",
		mali: "Mali",
		mauritania: "Mauritania",
		mauritius: "Mauritius",
		mozambique: "Mozambique",
		namibia: "Namibia",
		nicaragua: "Nicaragua",
		republic_of_Niger: "Republic_of_Niger",
		north_Korea: " North_Korea",
		reunion: "Reunion",
		san_Marino: "SAN Marino",
		senegal: "Senegal",
		sierra_Leone: "Sierra Leone",
		somalia: "Somalia",
		sudan: "Sudan",
		suriname: "Suriname",
		eswatini: "Eswatini",
		syria: "Syria",
		togo: "Togo",
		tonga: "Tonga",
		tunisia: "Tunisia",
		united_States_Virgin_Islands: "United States Virgin Islands",
		uganda: "Uganda",
		uruguay: "Uruguay",
		vatican: "Vatican",
		yemen: "Yemen",
		yugoslavia: "Yugoslavia",
		zambia: "Zambia",
		zimbabwe: "Zimbabwe",
		china: "China",
	}
};
