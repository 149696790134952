import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import { getToken } from '@/utils/auth'
import router from '../router/index'

// create an axios instance
const service = axios.create({
    baseURL: "https://api.amapluszon.xyz/", // url = base url + request url
    // baseURL: "http://127.0.0.1:50020/", // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 8000 // request timeout
})


// request interceptor
service.interceptors.request.use(
    config => {
        // do something before request is sent
        if (store.getters.token) {
            // let each request carry token
            // ['X-Token'] is a custom headers key
            // please modify it according to the actual situation
            config.headers['Authorization'] = getToken()
        }
        return config
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        const res = response.data
            // if the custom HttpStatusCode is not 200, it is judged as an error.
        if (res.HttpStatusCode !== 200 && res.HttpStatusCode) {
            Message({
                message: res.Message || 'Network Error',
                type: 'error',
                duration: 5 * 1000
            })
            return Promise.reject(new Error(res.message || 'Error'))
        } else {
            return res
        }
    },
    error => {
        if (error.response.data.HttpStatusCode === 401 || error.response.data.status === 401) {
            store.dispatch('user/resetToken').then(() => {
                router.push("/login")
            })
        }else{
            Message({
                message: error.response.data.Message ? error.response.data.Message : error.message,
                type: 'error',
                duration: 5 * 1000
            })
        }
        return Promise.reject(error)
    }
)

export default service
