import request from "@/utils/request";

export var uploadDomain = "https://api.amapluszon.xyz/api/v1/storage/upload";

export var serviceUrl = "https://secure.livechatinc.com/licence/18559791/open_chat.cgi"

export function getSystemParam(query) {
    return request({
        url: "/api/v1/system/system",
        method: "get",
        params: query,
    });
}

export function getItemList(query) {
    return request({
        url: "/api/v1/system/category",
        method: "get",
        params: query,
    });
}

export function getItemDetail(id) {
    return request({
        url: "/api/v1/system/category/" + id,
        method: "get",
    });
}

export function getLinkList(query) {
    return request({
        url: "/api/v1/system/link",
        method: "get",
        params: query,
    });
}

export function getArticleList(query) {
    return request({
        url: "/api/v1/system/article",
        method: "get",
        params: query,
    });
}

export function getArticleDetail(id) {
    return request({
        url: "/api/v1/system/article/" + id,
        method: "get",
    });
}

export function getArticleTypeList(query) {
    return request({
        url: "/api/v1/system/article-type",
        method: "get",
        params: query,
    });
}

export function getArticleTypeDetail(id) {
    return request({
        url: "/api/v1/system/article-type/" + id,
        method: "get",
    });
}

export function getRecordList(query) {
    return request({
        url: "/api/v1/profile/record",
        method: "get",
        params: query,
    });
}

export function getChannelList(query) {
    return request({
        url: "/api/v1/system/channel",
        method: "get",
        params: query,
    });
}

